body, html {
  margin: 0;
  padding: 0;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.container {
  max-width: 960px;
}

.pricing-header {
  max-width: 700px;
}

.card-deck .card {
  min-width: 220px;
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

@media (min-width: 992px) {
  .rounded-lg-3 { border-radius: .3rem; }
}

.bg-grey {
  background-color: #ccc;
}

.logo {
  width: 200px;
}

.logo img {
  width: 100%;
}

.rounded-corners-lg {
  border-radius: 25px;
}

.posts {
  list-style-type: none;
  padding-left: 0;
}

.products {
  list-style-type: none;
  padding-left: 0;
}

.products li {
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 25px;
  width: 300px;
}

.logo-lg {
  width: 125px;
}

.logo-lg img {
  width: 100%;
}